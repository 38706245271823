/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

.label{@apply text-primary mb-2 font-medium }
.input{@apply py-2.5 px-3 border border-primary rounded-lg}
.driverTip{ @apply rounded-full mx-2 bg-white text-sm 2xl:text-lg py-1 px-2 2xl:py-2 2xl:px-3 w-1/3 font-semibold text-primary cursor-pointer hover:ease-out hover:scale-105 duration-150 hover:bg-[#222222] hover:text-yellow-500 } 
.homeInfoContainer{   @apply flex flex-col bottom-0 md:bottom-20 xl:bottom-14 2xl:bottom-20 sm:left-6 sm:rounded-xl justify-end sm:w-80 2xl:w-96 w-full absolute bg-white text-center shadow-lg shadow-primary/40 z-30 overflow-hidden}
.rating{@apply text-secondary pb-2 px-5 bg-primary flex flex-col items-center pt-3 ml-0 rounded-t-xl md:rounded-xl}
.issues{@apply rounded-full m-2 text-[11px] 2xl:text-xs bg-white hover:bg-[#222222] w-auto hover:text-yellow-500 px-2 py-0.5 2xl:py-2 2xl:px-3 font-semibold text-primary cursor-pointer hover:ease-out hover:scale-105 duration-150}
.Button{ @apply rounded-full bg-white text-sm 2xl:text-lg py-1.5 px-8 2xl:py-2 2xl:px-3 font-semibold text-primary cursor-pointer hover:ease-out hover:scale-105 duration-150}
.card{ @apply bg-white border border-gray-200 rounded-sm py-4 m-5 mt-20}
.tableFilter{@apply h-full w-1/3 flex items-center justify-center}
.selectedFilter{@apply bg-primary text-secondary}
.smallButton{@apply rounded-full text-sm px-2 mx-2 2xl:px-4 font-semibold bg-primary text-secondary py-0.5 my-2 cursor-pointer hover:ease-out hover:scale-105 duration-150}
.titleSectionBuyTicket{ @apply flex items-center justify-center text-lg 2xl:text-lg  xl:text-sm  py-4 2xl:py-3 xl:py-1.5 text-secondary bg-primary w-full}
.selectLayout{@apply py-3 px-1.5 shadow-lg border my-4}
.schedule{@apply flex flex-col justify-center text-sm md:text-[10px] 2xl:text-xs items-center ml-3 space-y-2 h-32 md:h-24 overflow-y-hidden hover:overflow-auto cursor-pointer z-10}
.moreMenuItem{@apply px-3 text-center w-full  min-w-max flex cursor-pointer text-black hover:bg-primary/90 hover:text-secondary py-2}
.tableTH{@apply px-4 md:px-2 py-3 2xl:py-6 text-primary }
.tableTD{@apply px-4 md:px-2 py-3 2xl:py-6}
.creditCard{@apply bg-primary/90 text-secondary cursor-pointer hover:ease-out duration-300 shadow-lg shadow-primary/20 rounded-lg mr-8 2xl:mr-16 my-6 py-20 2xl:py-24 relative hover:bg-white hover:bg-opacity-10 hover:text-primary border border-primary }
.creditCardInfo{@apply flex flex-col items-start space-y-3 mx-3 space-x-3 text-lg top-6 absolute}
.ticket-card{
    @apply flex justify-between items-center w-11/12 rounded-md bg-secondary shadow-lg border border-primary max-h-44
}

.input {
    @apply w-full p-2.5 border border-primary/50 rounded-md mt-2
  }
  // buttons 
  .button-small {
      @apply px-5 py-2 rounded-full duration-200 cursor-pointer text-xs;
      &.primary{
          @apply bg-primary text-secondary shine-effect;
      }
      &.secondary{
          @apply bg-secondary/90 text-secondary shine-effect;
      }
      &.success{
          @apply bg-success text-secondary shine-effect;
      }
      &.danger {
          @apply bg-danger hover:bg-red-300 duration-300 text-secondary;
      }
      &.warning {
          @apply bg-warning hover:bg-yellow-300 duration-300 text-secondary;
      }
      &.info {
        @apply bg-info shine-effect text-secondary;
    }
  }
  .button {
      @apply 2xl:px-6 xl:px-5 md:px-8 px-4 py-2 md:py-3 xl:py-2 2xl:py-3.5 rounded-lg duration-200 cursor-pointer;
  
      &.primary{
          @apply bg-primary text-secondary;
      }
      &.secondary{
          @apply bg-secondary/90 text-secondary shine-effect;
      }
      &.success{
          @apply bg-success text-secondary shine-effect;
      }
      &.danger {
          @apply bg-danger shine-effect text-secondary;
      }
      &.warning {
          @apply bg-warning shine-effect text-secondary;
      }
      &.info {
        @apply bg-info shine-effect text-secondary;
    }
  }
  .title{
    @apply text-[1.7rem] md:text-6xl xl:text-4xl 2xl:text-5xl font-extrabold;
}
select, input{
    @apply w-full p-3 xl:p-1.5 2xl:p-3 border border-primary/50 rounded-md mt-2 bg-secondary text-start

}
input, select {
     -webkit-appearance: none; /* Desactiva estilos predeterminados en iOS */
     appearance: none; /* Desactiva estilos predeterminados en iOS */
}
.title-2{
  @apply text-4xl md:text-5xl xl:text-6xl tracking-wider font-bold;
}
.sub-title{
    @apply text-2xl md:text-3xl xl:text-4xl font-bold;
}
.sub-title-smaller{
    @apply text-xl md:text-2xl 2xl:text-3xl font-extrabold;
}
// shine effect
.shine-effect {
    position: relative;
    overflow: hidden;
}

.shine-effect::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    transform: skewX(-25deg);
    transition: all 0.7s;
    pointer-events: none;
}

.shine-effect:hover::before {
    left: 200%;
}    